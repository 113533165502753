/* @flow */

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { AdminRoute } from '../auth';
import { colors, breakpoints, Flex } from '../theme';
import { Logo, ExternalLink } from './ui';
import { Facebook, Instagram, Twitter } from '../images/icons';

const Social = styled.div`
  svg {
    height: 20px;
    width: 20px;
    margin-left: -2px;
    margin-right: 10px;
  }
`;

const Copyright = styled.div`
  border-top: 1px solid ${colors.lightGray};
  color: ${colors.warmGray};
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.4px;
  line-height: 17px;
  padding: 16px 122px;

  @media (max-width: ${breakpoints.sm.max}px) {
    padding: 16px 0;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;

  a {
    font-size: 15px;
    line-height: 26px;

    color: ${colors.warmGray};
    :hover {
      color: ${colors.darkGray};
      svg path {
        fill: ${colors.darkGray};
      }
    }
  }
`;

const Header = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 13px;
`;

const Container = styled.div`
  border-top: 1px solid ${colors.lightGray};
  padding: 0 80px;

  @media (max-width: ${breakpoints.md.max}px) {
    padding: 0 24px;
  }

  > ${Flex} {
    padding: 60px 122px;

    @media (max-width: ${breakpoints.lg.max}px) {
      padding: 60px 0;
    }

    ${Links} {
      padding: 0 4px;
    }

    @media (max-width: ${breakpoints.sm.max}px) {
      padding: 30px 0 20px;
      ${Logo} {
        margin-bottom: 10px;
      }
      flex-direction: column;
      ${Links} {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        grid-gap: 10px;
        margin: 10px 0;
        padding: 0;

        ${Header} {
          margin-bottom: 0;
        }

        a {
          line-height: 18px;
        }
      }
    }
  }
`;

class Page extends PureComponent<{}> {
  static defaultProps = {
    children: null,
    footer: false,
  };

  render() {
    return (
      <Container>
        <Flex justify="space-between">
          <Link to="/">
            <Logo />
          </Link>
          <Links>
            <Header>About</Header>
            <Link to="/about">About Us</Link>
            <ExternalLink to="https://housesogreen.com/2013/11/what-is-the-miles-per-gallon-rating-of-your-home-the-hers-index-in-plain-english/">
              What is HERS?
            </ExternalLink>
            <AdminRoute>{() => <Link to="/sitemap">Sitemap</Link>}</AdminRoute>
          </Links>
          <Links>
            <Header>Explore</Header>
            <Link to="/add/home">Homes</Link>
            <Link to="/add/certification">Certifications</Link>
            <Link to="/add/collection">Collections</Link>
            <Link to="/add/award">Awards</Link>
            <Link to="/add/supplier">Suppliers</Link>
          </Links>
          <Links>
            <Header>Legal</Header>
            <Link to="/terms-and-conditions">Terms and Conditions</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/legal">Legal</Link>
          </Links>
          <Links>
            <Header>Contact</Header>
            <Social>
              <ExternalLink to="https://facebook.com/housesogreen">
                <Facebook />
              </ExternalLink>
              <ExternalLink to="https://instagram.com/housesogreen">
                <Instagram />
              </ExternalLink>
              <ExternalLink to="https://twitter.com/housesogreen">
                <Twitter />
              </ExternalLink>
            </Social>
            <ExternalLink to="https://housesogreen.com/contact">
              Contact Us
            </ExternalLink>
            <ExternalLink to="https://housesogreen.com/contact">
              Advertise
            </ExternalLink>
          </Links>
        </Flex>
        <Copyright>&copy; {new Date().getFullYear()} House So Green</Copyright>
      </Container>
    );
  }
}

export default Page;
