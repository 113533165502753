/* @flow */

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import { LoadingPage } from './components/ui';
import { AuthenticatedRoute, UnauthenticatedRoute, AdminRoute } from './auth';

const Lazy = loader => Loadable({ loader, loading: LoadingPage });

const HomePage = Lazy(() => import('./pages/home'));
const SearchResultsPage = Lazy(() => import('./pages/search-results'));
const HomeDetailPage = Lazy(() => import('./pages/home-detail'));
const SupplierDetailPage = Lazy(() => import('./pages/supplier-detail'));
const CertificationDetailPage = Lazy(() =>
  import('./pages/certification-detail'),
);
const AwardDetailPage = Lazy(() => import('./pages/award-detail'));
const ProductDetailPage = Lazy(() => import('./pages/product-detail'));
const ArticleDetailPage = Lazy(() => import('./pages/article-detail'));
const Sitemap = Lazy(() => import('./pages/sitemap'));
const ScrapedData = Lazy(() => import('./pages/scraped-data'));
const EditPages = Lazy(() => import('./pages/edit'));

const AboutPage = Lazy(() => import('./pages/about'));
const TermsAndConditionsPage = Lazy(() =>
  import('./pages/terms-and-conditions'),
);
const PrivacyPolicyPage = Lazy(() => import('./pages/privacy-policy'));
const LegalPage = Lazy(() => import('./pages/legal'));

const LoginPage = Lazy(() => import('./pages/auth/login'));
const RegisterPage = Lazy(() => import('./pages/auth/register'));
const ConfirmEmailPage = Lazy(() => import('./pages/auth/confirm-email'));
const ResendConfirmationPage = Lazy(() =>
  import('./pages/auth/resend-confirmation'),
);
const ForgotPasswordPage = Lazy(() => import('./pages/auth/forgot-password'));
const ConfirmForgottenPasswordPage = Lazy(() =>
  import('./pages/auth/confirm-forgotten-password'),
);

const redirectHome = () => <Redirect to="/" />;

export default () => (
  <Switch>
    <Route exact path="/" component={HomePage} />
    <Route path="/s/" component={SearchResultsPage} />
    <Route exact path="/homes/:id/:slug?" component={HomeDetailPage} />
    <Route exact path="/suppliers/:id/:slug?" component={SupplierDetailPage} />
    <Route
      exact
      path="/certifications/:id/:slug?"
      component={CertificationDetailPage}
    />
    <Route exact path="/awards/:id/:slug?" component={AwardDetailPage} />
    <Route exact path="/products/:id/:slug?" component={ProductDetailPage} />
    <Route exact path="/articles/:id/:slug?" component={ArticleDetailPage} />

    <Route exact path="/about" component={AboutPage} />
    <Route
      exact
      path="/terms-and-conditions"
      component={TermsAndConditionsPage}
    />
    <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
    <Route exact path="/legal" component={LegalPage} />

    <AdminRoute to="/" exact path="/sitemap" component={Sitemap} />
    <AdminRoute to="/" exact path="/admin/data" component={ScrapedData} />
    <AuthenticatedRoute promptLogin path="/(add|edit)" component={EditPages} />

    <UnauthenticatedRoute
      to="/"
      exact
      path="/user/login"
      component={LoginPage}
    />
    <UnauthenticatedRoute
      to="/"
      exact
      path="/user/register"
      component={RegisterPage}
    />
    <UnauthenticatedRoute
      to="/"
      exact
      path="/user/register/confirm"
      component={ConfirmEmailPage}
    />
    <UnauthenticatedRoute
      to="/"
      exact
      path="/user/register/resend-confirmation"
      component={ResendConfirmationPage}
    />
    <UnauthenticatedRoute
      to="/"
      exact
      path="/user/forgot-password"
      component={ForgotPasswordPage}
    />
    <UnauthenticatedRoute
      to="/"
      exact
      path="/user/forgot-password/confirm"
      component={ConfirmForgottenPasswordPage}
    />

    <Route render={redirectHome} />
  </Switch>
);
