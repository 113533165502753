/* @flow */

import React from 'react';
import Helmet from 'react-helmet';

export const truncate = (content: string, maxLength: number): string => {
  if (content.length <= maxLength) return content;

  const parts = content.slice(0, maxLength - 3).split(' ');
  if (content[maxLength - 3] !== ' ') parts.splice(-1, 1);

  return `${parts.join(' ')}...`;
};

type Props = {
  children?: string,
};

const MetaDescription = ({ children }: Props) =>
  children ? (
    <Helmet>
      <meta name="description" content={truncate(children, 300)} />
    </Helmet>
  ) : null;

MetaDescription.defaultProps = {
  children: undefined,
};

export default MetaDescription;
