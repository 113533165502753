/* @flow */

import { Component, type Node } from 'react';

const { HTMLElement } = window;

type ScrollTo = {
  to: true,
  by?: null,
  top?: number | boolean,
  left?: number | boolean,
};

type ScrollBy = {
  to?: null,
  by: true,
  top?: number,
  left?: number,
};

type BaseProps = {
  children?: Node,
  container?: HTMLElement | window,
  on: 'mount',
  behavior?: 'smooth' | 'instant' | 'auto',
  respectHash?: boolean,
};

type Props = (BaseProps & ScrollTo) | (BaseProps & ScrollBy);

class Scroll extends Component<Props> {
  static defaultProps = {
    children: null,
    container: window,
    behavior: 'auto',
    top: 0,
    left: 0,
    respectHash: false,
  };

  componentDidMount() {
    if (this.props.on === 'mount') {
      this.scroll();
    }
  }

  scroll() {
    const { container = window, behavior } = this.props;

    const element =
      this.props.respectHash &&
      window.location.hash &&
      document.querySelector(window.location.hash);

    if (element) {
      element.scrollIntoView({ block: 'start', inline: 'nearest', behavior });
    } else {
      const top = this.props.top === true ? 0 : this.props.top || 0;
      const left = this.props.left === true ? 0 : this.props.left || 0;

      try {
        container[this.props.by ? 'scrollBy' : 'scrollTo']({
          top,
          left,
          behavior,
        });
      } catch (error) {
        // Older browsers only support the 2-argument version
        container[this.props.by ? 'scrollBy' : 'scrollTo'](top, left);
      }
    }
  }

  render() {
    return this.props.children;
  }
}

export default Scroll;
