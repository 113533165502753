/* @flow */

import React from 'react';

const ExternalLink = ({ to, href, children, ...props }: any) => {
  const website = to || href;
  if (!website) return <a {...props}>{children}</a>;

  const externalHref = /^(mailto|tel|https?):/.test(website)
    ? website
    : `http://${website}`;

  let inner = children;
  if (!inner) {
    inner = externalHref.replace(/^(mailto|tel):/, '');
  }

  return (
    <a target="_blank" rel="noopener noreferrer" href={externalHref} {...props}>
      {inner}
    </a>
  );
};

export default ExternalLink;
