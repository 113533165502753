import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { Auth } from 'aws-amplify';

const authLink = setContext(async (_, { headers }) => {
  try {
    const session = await Auth.currentSession();
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${session.idToken.jwtToken}`,
      },
    };
  } catch (error) {
    return { headers };
  }
});

const client = new ApolloClient({
  link: authLink.concat(
    new HttpLink({
      uri: process.env.REACT_APP_API_URI,
    }),
  ),
  assumeImmutableResults: true,
  cache: new InMemoryCache(),
});

export default client;

export const adminClient = new ApolloClient({
  link: authLink.concat(
    new HttpLink({
      uri: process.env.REACT_APP_SCRAPER_API_URI,
    }),
  ),
  assumeImmutableResults: true,
  cache: new InMemoryCache(),
});
