import React from 'react';
import { branch, nest, renderComponent } from 'recompose';
import Spinner from 'react-spinkit';
import styled from 'styled-components';

import Page from '../page';
import { colors, Section } from '../../theme';

const LoadingWrapper = styled.div`
  margin: 10px 0;
`;

export const Loading = () => (
  <Spinner name="cube-grid" color={colors.green} fadeIn="none" />
);

export const withLoading = (isLoading, Wrapper = LoadingWrapper) =>
  branch(isLoading, renderComponent(nest(Wrapper, Loading)));

export const LoadingPage = () => (
  <Page nav footer>
    <Section>
      <Loading />
    </Section>
  </Page>
);
