/* @flow */

import React, { PureComponent, Fragment, type Node } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Collapse,
} from 'reactstrap';
import { Toggle } from 'react-powerplug';

import { colors, breakpoints } from '../theme';
import { Logo, ExternalLink } from './ui';
import LoginController from './login-controller';

const HeaderNavbar = styled(Navbar)`
  border-bottom: 1px solid ${colors.lightGray};
  z-index: 10;
  background-color: #fff;
  .navbar-brand {
    height: 55px;
    display: flex;
    align-items: center;
  }
`;

const PaddedNavItem = styled(NavItem)`
  @media (min-width: ${breakpoints.md.min}px) {
    margin-left: 28px;
  }
`;

const SearchNav = styled(Nav)`
  &,
  .nav-item {
    flex: 1;
  }

  > .nav-item > label {
    margin: 2.5px 0;
  }

  @media (min-width: ${breakpoints.md.min}px) {
    max-width: 330px;
  }
`;

const DefaultNavButton = () => (
  <Switch>
    <Route path="/(add|edit|user)" render={() => null} />
    <Route>
      {() => (
        <PaddedNavItem>
          <Button color="primary" tag={Link} to="/add">
            + Add to HSG
          </Button>
        </PaddedNavItem>
      )}
    </Route>
  </Switch>
);

type Props = {
  sticky?: boolean,
  search?: Node,
  button?: Node,
};

class AppHeader extends PureComponent<Props> {
  static defaultProps = {
    sticky: false,
    search: null,
    button: null,
  };

  render() {
    return (
      <Fragment>
        <Toggle initial={false}>
          {({ on, toggle }) => (
            <HeaderNavbar
              className={this.props.sticky ? 'sticky-top' : ''}
              expand="md"
              light
            >
              <NavbarBrand tag={Link} to="/">
                <Logo />
              </NavbarBrand>
              <NavbarToggler onClick={toggle} aria-label="Toggle navigation" />
              <Collapse isOpen={on} navbar>
                <SearchNav>
                  {this.props.search && <NavItem>{this.props.search}</NavItem>}
                </SearchNav>
                <Nav className="ml-auto" navbar>
                  <NavItem>
                    <NavLink
                      tag={ExternalLink}
                      to="https://housesogreen.com/2013/11/what-is-the-miles-per-gallon-rating-of-your-home-the-hers-index-in-plain-english/"
                      target="hsg-blog"
                      rel="noopener"
                    >
                      What is HERS?
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={ExternalLink}
                      to="https://housesogreen.com/blog"
                      target="hsg-blog"
                    >
                      Blog
                    </NavLink>
                  </NavItem>
                  <LoginController />
                  {this.props.button ? (
                    <PaddedNavItem>{this.props.button}</PaddedNavItem>
                  ) : (
                    <DefaultNavButton />
                  )}
                </Nav>
              </Collapse>
            </HeaderNavbar>
          )}
        </Toggle>
      </Fragment>
    );
  }
}

export default AppHeader;
