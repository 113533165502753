/* @flow */

import React, { Component } from 'react';
import { Button, NavItem } from 'reactstrap';

import { withAuth, type AuthProps } from '../auth';

type Props = {
  auth: AuthProps,
};

class LogoutButton extends Component<Props> {
  signOut = () => {
    const { auth } = this.props;
    auth.signOut(auth.isAuthenticatedRoute ? '/' : '');
  };

  render() {
    const { user } = this.props.auth;

    if (!user) return null;

    return (
      <NavItem>
        <Button
          className="nav-link w-100 text-left justify-content-start"
          color="link"
          onClick={this.signOut}
          title={`Logged in as ${user.email}`}
        >
          Logout
        </Button>
      </NavItem>
    );
  }
}

export default withAuth(LogoutButton);
