/* @flow */

import React, { Component } from 'react';
import type { Node } from 'react';
import styled from 'styled-components';

import AppHeader from './app-header';
import AppFooter from './app-footer';
import Scroll from './scroll';
import { defaultMetaDescription } from '../meta';
import { Title, MetaDescription } from './seo';

const Container = styled.main`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

type Props = {
  children?: Node,
  footer?: boolean,
  scrollToTop?: boolean,
};

class Page extends Component<Props> {
  static defaultProps = {
    children: null,
    footer: false,
    scrollToTop: true,
  };

  render() {
    const { children, footer, scrollToTop, ...props } = this.props;

    return (
      <Container>
        <Title>House So Green</Title>
        <MetaDescription>{defaultMetaDescription}</MetaDescription>
        {scrollToTop && <Scroll to top on="mount" respectHash />}
        <AppHeader {...props} />
        {children}
        {footer && <AppFooter />}
      </Container>
    );
  }
}

export default Page;
