import 'core-js';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import 'url-polyfill';
import 'react-dates/initialize';

import 'slick-carousel/slick/slick.css';
import 'react-dates/lib/css/_datepicker.css';
import './index.scss';

import './analytics';
import { AuthController } from './auth';
import App from './app';
import apolloClient from './apollo';
import * as serviceWorker from './serviceWorker';

render(
  <BrowserRouter>
    <AuthController>
      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    </AuthController>
  </BrowserRouter>,
  document.getElementById('root'),
);

if (module.hot) module.hot.accept();

serviceWorker.register({
  onUpdate: () => window.location.reload(),
});
