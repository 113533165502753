/* @flow */

import React, { Fragment } from 'react';
import styled from 'styled-components';
import { withRouter, Route, Switch, Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';

import { colors } from '../theme';
import { UnauthenticatedRoute } from '../auth';
import LogoutButton from './logout-button';

const LoginLink = styled(
  withRouter(({ location, className }) => (
    <NavLink
      className={className}
      tag={Link}
      to={`/user/login?redirect=${location.pathname}`}
    >
      Login
    </NavLink>
  )),
)`
  .navbar-nav & {
    padding-right: 0.5rem;
  }
`;

const RegisterLink = styled(
  withRouter(({ location, className }) => (
    <NavLink
      className={className}
      tag={Link}
      to={`/user/register?redirect=${location.pathname}`}
    >
      Sign-up
    </NavLink>
  )),
)`
  ${LoginLink} + & {
    position: relative;
    .navbar-nav & {
      padding-left: 1px;
    }

    :before {
      content: '|';
      position: absolute;
      left: -5px;
      color: ${colors.green};
    }
  }
`;

const SoloRegisterLink = () => (
  <NavItem>
    <RegisterLink />
  </NavItem>
);
const SoloLoginLink = () => (
  <NavItem>
    <LoginLink />
  </NavItem>
);

const BothLinks = () => (
  <NavItem className="d-flex">
    <LoginLink />
    <RegisterLink />
  </NavItem>
);

export default () => (
  <Fragment>
    <LogoutButton />
    <UnauthenticatedRoute>
      {() => (
        <Switch>
          <Route path="/user/login" component={SoloRegisterLink} />
          <Route path="/user/register" component={SoloLoginLink} />
          <Route component={BothLinks} />
        </Switch>
      )}
    </UnauthenticatedRoute>
  </Fragment>
);
