/* flow */

import React, { Node } from 'react';
import styled, { css } from 'styled-components';

import breakpoints from './breakpoints.json';

export { breakpoints };

export const colors = {
  shadow: '#ececec',
  lightGray: '#dbdbdb',
  darkGray: '#484848',
  warmGray: '#767676',
  softGray: '#979797',
  green: '#01b07a',
  red: '#f36a4e',
  rgba: {
    shadow: opacity => `rgba(236, 236, 236, ${opacity})`,
    lightGray: opacity => `rgba(219, 219, 219, ${opacity})`,
    darkGray: opacity => `rgba(72, 72, 72, ${opacity})`,
    warmGray: opacity => `rgba(123, 123, 123, ${opacity})`,
    softGray: opacity => `rgba(151, 151, 151, ${opacity})`,
    green: opacity => `rgba(1, 176, 122, ${opacity})`,
    red: opacity => `rgba(243, 106, 78, ${opacity})`,
  },
};

export const styles = {
  allCapsTitle: css`
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 16px;
    color: ${colors.darkGray};
  `,
  cardTitle: css`
    color: ${colors.darkGray};
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  `,
  cardH5: css`
    color: ${colors.darkGray};
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    margin: 0;
  `,
  detailPageHeadline: css`
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
    color: ${colors.darkGray};
    margin: 0;
  `,
  links: css`
    font-size: 13px;
    font-weight: 500;
    line-height: 21px;
    color: ${colors.green};
    cursor: pointer;
    :focus,
    :hover {
      text-decoration: underline;
    }
  `,
  paragraph: css`
    font-size: 15px;
    font-weight: 300;
    color: ${colors.darkGray};
    margin-bottom: 1rem;
  `,
  sectionHeadlines: css`
    font-size: 18px;
    font-weight: 600;
    color: ${colors.darkGray};
    margin: 0;
  `,
};

styles.listItemParameters = styles.cardTitle;

export const AllCapsTitle = styled.div`
  ${styles.allCapsTitle};
`;
export const CardTitle = styled.div`
  ${styles.cardTitle};
`;
export const CardH5 = styled.div`
  ${styles.cardH5};
`;
export const DetailPageHeadline = styled.h1`
  ${styles.detailPageHeadline};
`;
export const Paragraph = styled.div`
  ${styles.paragraph};
`;
export const SectionHeadlines = styled.h2`
  ${styles.sectionHeadlines};
`;
export const Heading = styled.h4`
  ${styles.paragraph};
  font-weight: 600;
`;

// max-width can actually go beyond 1px,
// but this allows for text overflow on small screens
export const DetailValue = styled.div.attrs({
  children: ({ value }: { value: Node }) =>
    value == null ? <span>&nbsp;</span> : value,
  title: props => props.value,
})`
  &,
  a {
    font-size: 15px;
    font-weight: 500;
    color: ${colors.darkGray};
    line-height: 16px;
    max-width: 1px;

    @media (min-width: ${breakpoints.md.min}px) {
      text-align: right;
      max-width: 100%;
    }
  }
`;

export const Detail = styled.div`
  display: flex;
  border-bottom: 2px solid ${colors.lightGray};
  padding: 19px 0 17px;
  align-items: center;

  > div {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: ${breakpoints.sm.max}px) {
    flex-wrap: wrap;
    padding: 10px 0 5px;

    > div {
      min-width: 100%;
      width: 100%;
      :not(:last-child) {
        padding-bottom: 4px;
      }
    }
  }
`;

export const Header = styled.div`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.2px;
  color: ${colors.darkGray};
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${breakpoints.sm.max}px) {
    flex-wrap: wrap;
    .btn {
      width: 100%;
      margin-top: 8px;
    }
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 540px;
  margin: 24px;

  ${props =>
    props.full &&
    css`
      max-width: 1024px;
      align-self: center;
    `};

  @media (min-width: ${breakpoints.md.min}px) {
    margin: 40px;
  }

  @media (min-width: ${breakpoints.lg.min}px) {
    margin: 40px 80px;
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  align-items: ${props => props.align || 'stretch'};
  justify-content: ${props => props.justify || 'flex-start'};
  flex-wrap: ${props => (props.wrap ? 'wrap' : 'nowrap')};
`;
