import React from 'react';
import styled, { css } from 'styled-components';

import { Arrow } from '../../images/icons';

const rotation = props => {
  if (props.up)
    return css`
      transform: rotate(180deg);
    `;
  if (props.right)
    return css`
      transform: rotate(-90deg);
    `;
  if (props.left)
    return css`
      transform: rotate(90deg);
    `;
  return '';
};

const size = props => {
  if (props.large) {
    return css`
      height: 6px;
      width: 11px;
      margin-left: 10px;
    `;
  }

  return css`
    height: 4px;
    width: 8px;
    margin-left: 4px;
  `;
};

const ArrowWrapper = styled.span`
  display: flex;
  transition: transform 0.3s;
  ${size};
  ${rotation};
`;

const ArrowIcon = props => (
  <ArrowWrapper {...props}>
    <Arrow {...props} />
  </ArrowWrapper>
);

export default ArrowIcon;
