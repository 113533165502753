/* @flow */

import React from 'react';

type Props = {
  list: ?Array<{ name: string, route: string }>,
};

const Breadcrumb = ({ list }: Props) =>
  list &&
  list.length > 0 && (
    <script type="application/ld+json">
      {JSON.stringify(
        {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: list.map(({ name, route }, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            name,
            item: `${window.location.origin}${encodeURI(route)}`,
          })),
        },
        null,
        2,
      )}
    </script>
  );

export default Breadcrumb;
