/* eslint-disable */

const google = () => {
  const key = process.env.REACT_APP_GOOGLE_ANALYTICS;
  if (!key) return;

  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${key}`;
  document.body.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', key);
};

const hotjar = () => {
  const key = process.env.REACT_APP_HOTJAR_ANALYTICS;
  if (!key) return;

  (function(h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function() {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: key, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
};

const heap = () => {
  const key = process.env.REACT_APP_HEAP_ANALYTICS;
  if (!key) return;

  (window.heap = window.heap || []),
    (heap.load = function(e, t) {
      (window.heap.appid = e), (window.heap.config = t = t || {});
      var r = t.forceSSL || 'https:' === document.location.protocol,
        a = document.createElement('script');
      (a.type = 'text/javascript'),
        (a.async = !0),
        (a.src =
          (r ? 'https:' : 'http:') +
          '//cdn.heapanalytics.com/js/heap-' +
          e +
          '.js');
      var n = document.getElementsByTagName('script')[0];
      n.parentNode.insertBefore(a, n);
      for (
        var o = function(e) {
            return function() {
              heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
            };
          },
          p = [
            'addEventProperties',
            'addUserProperties',
            'clearEventProperties',
            'identify',
            'resetIdentity',
            'removeEventProperty',
            'setEventProperties',
            'track',
            'unsetEventProperty',
          ],
          c = 0;
        c < p.length;
        c++
      )
        heap[p[c]] = o(p[c]);
    });
  heap.load(key);
};

google();
hotjar();
heap();
