/* @flow */

import React, { createElement, type Node } from 'react';
import styled, { css } from 'styled-components';
import { startCase } from 'lodash';

import { colors } from '../../theme';
import * as icons from '../../images/icons';

const OuterImageContainer = styled.div`
  position: relative;
  border-radius: 4px;

  ${props =>
    props.small
      ? css`
          width: 55px;
          height: ${Math.round(55 * props.aspectRatio)}px;
          margin-right: 15px;
        `
      : css`
          margin-bottom: 8px;
          box-shadow: 0 2px 8px 0 ${colors.shadow};
          border: 1px solid ${colors.lightGray};
          width: 100%;
          height: 0;
          padding-top: calc(100% * ${props.aspectRatio} - 2px);
        `};
`;

const InnerImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    max-width: 80%;
    max-height: 80%;
  }

  img {
    object-fit: ${props => (props.cover ? 'cover' : 'contain')};
    border-radius: 4px;
    width: 100%;
    height: 100%;
    margin: 0;
    ${props =>
      props.padded &&
      css`
        width: 50%;
        height: 50%;
      `};
  }
`;

type Props = {
  type:
    | 'home'
    | 'supplier'
    | 'certification'
    | 'award'
    | 'collection'
    | 'product'
    | 'article',
  src?: string,
  small?: boolean,
  padded?: boolean,
  cover?: boolean,
  aspectRatio?: number | string,
  alt?: string,
  children?: Node,
};

const CardImage = ({
  src,
  type,
  small,
  padded,
  alt,
  cover,
  aspectRatio,
  children,
  ...props
}: Props) => (
  <OuterImageContainer aspectRatio={aspectRatio} small={small} {...props}>
    <InnerImageContainer padded={padded} cover={cover}>
      {src ? (
        <img src={`${src}@300W`} alt={alt} />
      ) : (
        createElement(icons[startCase(type)], { color: colors.softGray, alt })
      )}
    </InnerImageContainer>
    {children}
  </OuterImageContainer>
);

CardImage.defaultProps = {
  src: undefined,
  small: false,
  padded: false,
  cover: false,
  aspectRatio: 1,
  alt: '',
  children: undefined,
};

export default CardImage;
