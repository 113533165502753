/* @flow */
/* eslint-disable react/no-unused-state */

import React, { createContext, Component, type Node } from 'react';
import {
  withRouter,
  Route,
  Redirect,
  type Location,
  type RouterHistory,
} from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { FORM_ERROR } from 'final-form';
import Amplify, { Auth } from 'aws-amplify';
import { get } from 'lodash';

const lowerCase = string => (string || '').toLowerCase();

const { URLSearchParams } = window;

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
  },
});

type SignIn = {
  email: string,
  password: string,
};

type SignUp = SignIn & {
  firstName: string,
  lastName: string,
};

type SendConfirmation = {
  email: string,
};

type Confirm = {
  email: string,
  confirmationCode: string,
};

type ForgotPasswordSubmit = Confirm & SignIn;

type Props = {
  children: Node,
  location: Location,
  history: RouterHistory,
};

type SubmissionError = {
  [formField: string]: string,
};

type User = {
  email: string,
  isAdmin: boolean,
  given_name?: string,
  family_name?: string,
};

type FormSubmit = Promise<?SubmissionError>;

type State = {
  user: null | User,
  redirect: null | string,
  redirecting: boolean,
  confirming: null | string,
  loading: boolean,
  signIn: SignIn => FormSubmit,
  signUp: SignUp => FormSubmit,
  sendConfirmation: SendConfirmation => FormSubmit,
  confirmEmail: Confirm => FormSubmit,
  forgotPassword: SendConfirmation => FormSubmit,
  forgotPasswordSubmit: ForgotPasswordSubmit => FormSubmit,
  signOut: (redirect: ?string) => FormSubmit,
};

const AuthContext = createContext({
  user: null,
  redirect: null,
  redirecting: false,
  confirming: null,
  loading: true,
  signIn: async () => null,
  signUp: async () => null,
  sendConfirmation: async () => null,
  confirmEmail: async () => null,
  forgotPassword: async () => null,
  forgotPasswordSubmit: async () => null,
  signOut: async () => null,
});

export default AuthContext.Consumer;

class AuthControllerBase extends Component<Props, State> {
  static getDerivedStateFromProps(props, state) {
    const redirect = new URLSearchParams(props.location.search).get('redirect');

    if (!redirect && state.redirect === props.location.pathname)
      return { redirect: null };

    return redirect ? { redirect } : {};
  }

  state = {
    user: null,
    redirect: null,
    redirecting: false,
    confirming: null,
    loading: true,
    signIn: async ({ email, password }: SignIn) => {
      let errorMessage;
      try {
        await Auth.signIn(lowerCase(email), password);
        const { attributes } = await Auth.currentAuthenticatedUser();
        this.setState({ user: attributes });

        const { redirect } = this.state;

        if (redirect) {
          this.setState({ redirecting: true }, () => {
            this.props.history.replace(redirect);
            this.setState({ redirecting: false });
          });
        }
      } catch (error) {
        errorMessage = error.message || error;
      }

      await this.getCurrentUser();

      return errorMessage && { [FORM_ERROR]: errorMessage };
    },
    signUp: async ({ email, password, firstName, lastName }: SignUp) => {
      let errorMessage;

      const username = lowerCase(email);

      const attributes = {
        email: username,
        given_name: firstName,
        family_name: lastName,
      };

      if (!firstName) delete attributes.given_name;
      if (!lastName) delete attributes.family_name;

      try {
        await Auth.signUp({
          username,
          password,
          attributes,
        });

        this.setState({ confirming: email });
      } catch (error) {
        errorMessage = error.message || error;
      }

      return errorMessage && { [FORM_ERROR]: errorMessage };
    },
    sendConfirmation: async ({ email }: SendConfirmation) => {
      let errorMessage;
      try {
        await Auth.resendSignUp(lowerCase(email));
        this.setState({ confirming: email });
      } catch (error) {
        errorMessage = error.message || error;
      }

      return errorMessage && { [FORM_ERROR]: errorMessage };
    },
    confirmEmail: async ({ email, confirmationCode }: Confirm) => {
      let errorMessage;
      try {
        await Auth.confirmSignUp(lowerCase(email), confirmationCode);
      } catch (error) {
        errorMessage = error.message || error;
      }

      return errorMessage && { [FORM_ERROR]: errorMessage };
    },
    forgotPassword: async ({ email }: SendConfirmation) => {
      let errorMessage;
      try {
        await Auth.forgotPassword(lowerCase(email));
        this.setState({ confirming: email });
      } catch (error) {
        errorMessage = error.message || error;
      }

      return errorMessage && { [FORM_ERROR]: errorMessage };
    },
    forgotPasswordSubmit: async ({
      email,
      confirmationCode,
      password,
    }: ForgotPasswordSubmit) => {
      let errorMessage;
      try {
        await Auth.forgotPasswordSubmit(
          lowerCase(email),
          confirmationCode,
          password,
        );
      } catch (error) {
        errorMessage = error.message || error;
      }

      return errorMessage && { [FORM_ERROR]: errorMessage };
    },
    signOut: async redirect => {
      let errorMessage;
      try {
        await Auth.signOut();
        if (redirect) this.props.history.push(redirect);
        this.setState({ user: null, redirect: null });
      } catch (error) {
        errorMessage = error.message || error;
      }

      return errorMessage && { [FORM_ERROR]: errorMessage };
    },
  };

  componentDidMount() {
    this.getCurrentUser();
    window.addEventListener('storage', this.getCurrentUser);
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.getCurrentUser);
  }

  getCurrentUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      this.setState({
        user: {
          ...user.attributes,
          isAdmin:
            get(
              user,
              'signInUserSession.accessToken.payload.cognito:groups[0]',
            ) === 'admin',
        },
      });
    } catch (error) {
      this.setState({ user: null });
    }

    this.setState({ confirming: null, loading: false });
  };

  render() {
    return (
      <AuthContext.Provider value={this.state}>
        {!this.state.loading && this.props.children}
      </AuthContext.Provider>
    );
  }
}

export const AuthController = withRouter(AuthControllerBase);

const IsAuthenticatedRoute = createContext(false);

export const withAuth = (Cmp: any) => (props: any) => (
  <IsAuthenticatedRoute.Consumer>
    {isAuthenticatedRoute => (
      <AuthContext.Consumer>
        {authState => (
          <Cmp auth={{ isAuthenticatedRoute, ...authState }} {...props} />
        )}
      </AuthContext.Consumer>
    )}
  </IsAuthenticatedRoute.Consumer>
);

export type AuthProps = State & {
  isAuthenticatedRoute: boolean,
};

type AuthRouteProps = {
  validate: (user: null | User) => boolean,
  to?: string,
  isAuthenticatedRoute?: boolean,
  component?: Component<any, any>,
  render?: () => Node,
  children?: () => Node,
};

export const AuthRoute = ({
  validate,
  to,
  component,
  render,
  children,
  isAuthenticatedRoute,
  ...routeProps
}: AuthRouteProps) => (
  <Route {...routeProps}>
    {() => (
      <AuthContext.Consumer>
        {({ user, redirecting }) =>
          validate(user) ? (
            <IsAuthenticatedRoute.Provider value={isAuthenticatedRoute}>
              {/* $FlowFixMe */}
              <Route component={component} render={render} {...routeProps}>
                {children}
              </Route>
            </IsAuthenticatedRoute.Provider>
          ) : (
            to && !redirecting && <Redirect to={to} />
          )
        }
      </AuthContext.Consumer>
    )}
  </Route>
);

AuthRoute.defaultProps = {
  to: null,
  component: null,
  render: null,
  children: null,
  isAuthenticatedRoute: false,
};

export const AuthenticatedRoute = compose(
  withRouter,
  withProps(props => ({
    to: props.promptLogin && `/user/login?redirect=${props.location.pathname}`,
    validate: user => !!user,
    isAuthenticatedRoute: true,
  })),
)(AuthRoute);

export const UnauthenticatedRoute = withProps({ validate: user => !user })(
  AuthRoute,
);
export const AdminRoute = withProps({
  validate: user => !!user && user.isAdmin,
  isAuthenticatedRoute: true,
})(AuthRoute);
