import React from 'react';
import styled from 'styled-components';

const Logo = props => (
  <picture {...props}>
    <source
      type="image/webp"
      srcSet={`${process.env.PUBLIC_URL}/images/logo.webp`}
    />
    <img
      alt="House So Green"
      src={`${process.env.PUBLIC_URL}/images/logo.png`}
    />
  </picture>
);

export default styled(Logo)`
  &,
  img {
    height: 35px;
    width: 143px;
  }
`;
