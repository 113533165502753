/* @flow */

import React from 'react';
import Helmet from 'react-helmet';

type Props = {
  children: string,
};

export default ({ children }: Props) => (
  <Helmet>
    <title>{children}</title>
  </Helmet>
);
